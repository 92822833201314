import React from 'react'
import SC from './elements'

import Offer from '../assets/offer.png'
import Evoucher from '../assets/evoucher.svg'
import CouponVoucher from '../assets/coupon.svg'
import ValueVoucher from '../assets/value-voucher.svg'
import FreeGiftVoucher from '../assets/free-gift.svg'
import OfferFaded from '../assets/offer-faded.svg'
import EvoucherFaded from '../assets/evoucher-faded.svg'
import CouponVoucherFaded from '../assets/coupon-faded.svg'
import ValueVoucherFaded from '../assets/value-voucher-faded.svg'
import FreeGiftVoucherFaded from '../assets/free-gift-faded.svg'

const voucherIcons = {
  qr_code: Evoucher,
  barcode: CouponVoucher,
  dollar_off: ValueVoucher,
  percent_off: Offer,
  free_gift: FreeGiftVoucher,
}

const voucherIconsFaded = {
  qr_code: EvoucherFaded,
  barcode: CouponVoucherFaded,
  dollar_off: ValueVoucherFaded,
  percent_off: OfferFaded,
  free_gift: FreeGiftVoucherFaded,
}

const VoucherIcon = ({ iconType, onLoad, isPast, type, className = '' }) => {
  let voucherIcon = voucherIcons[iconType] || iconType
  if (isPast) {
    voucherIcon = voucherIconsFaded[iconType] || iconType
  }
  return (
    <SC.StyledVoucherImage
      src={voucherIcon}
      type={type}
      width="3.75rem"
      height="3.75rem"
      isPast={isPast}
      className={className}
      onLoad={onLoad}
      data-testid="voucher-image"
      data-icontype={iconType}
    />
  )
}
export default VoucherIcon

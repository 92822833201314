import React, { useState, useEffect, useCallback } from 'react'
import SC from './elements'
import lineBreaker from '../utils/lineBreaker'
import Link from '@ntuctech/devex-tangram/Link'
import Tabs, { TabPanel, TabList, Tab } from '@ntuctech/devex-tangram/Tabs'

const TabSection = ({ whereToRedeem, tnc, isSideSheet }) => {
  const [showSeeAll, setShowSeeAll] = useState(false)

  const checkLineCount = useCallback(
    e => {
      setShowSeeAll(false)
      let content = whereToRedeem
      if (e === 1) {
        content = tnc
      }
      const lineCount = content.split(/\r\n|\r|\n/).length
      setShowSeeAll(lineCount > 5 && !showSeeAll)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [whereToRedeem, tnc]
  )

  useEffect(() => {
    checkLineCount()
  }, [checkLineCount])

  return (
    <SC.StyledTabBox pb={2} my={2}>
      <Tabs onChange={checkLineCount}>
        <TabList className="details-tab">
          <Tab>Where to redeem</Tab>
          <Tab>T&amp;Cs</Tab>
        </TabList>
        <TabPanel>
          <SC.DetailsTab
            isSideSheet={isSideSheet}
            variant="body3"
            dangerouslySetInnerHTML={{ __html: lineBreaker(whereToRedeem) }}
            data-seeall={showSeeAll}
          />
          {showSeeAll && (
            <SC.Box mx={3}>
              <Link
                size="m"
                target="_blank"
                rel="nofollow"
                color="#1557BF"
                weight="bold"
                onClick={() => {
                  setShowSeeAll(false)
                }}
              >
                See all
              </Link>
            </SC.Box>
          )}
        </TabPanel>
        <TabPanel className="details-tabpanel fade">
          <SC.DetailsTab
            isSideSheet={isSideSheet}
            variant="body3"
            dangerouslySetInnerHTML={{ __html: lineBreaker(tnc) }}
            data-seeall={showSeeAll}
          />
          {showSeeAll && (
            <SC.Box mx={3}>
              <Link
                size="m"
                target="_blank"
                rel="nofollow"
                color="#1557BF"
                weight="bold"
                onClick={() => {
                  setShowSeeAll(false)
                }}
              >
                See all
              </Link>
            </SC.Box>
          )}
        </TabPanel>
      </Tabs>
    </SC.StyledTabBox>
  )
}

export default TabSection
